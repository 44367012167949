import React from 'react';
import { BiArrowBack } from 'react-icons/bi';
import { Link } from 'react-router-dom';

import { formatDateTime } from '../utility/date';
import { formatTransactionTableAmount } from '../utility/currency';
import { mapRecentTransactionsByDate } from 'utility/transactions';
import CurrencyFlag from './svg/CurrencyFlag';
import TransactionType from './TransactionType';

const RecentTransactions = ({
  transactions,
  accountNames = [],
  transactionsUrl = '/dashboard/transactions?productType=credit_card',
  includePayments = true,
  productType,
}) => {
  const transactionsByDate = mapRecentTransactionsByDate(transactions, includePayments);

  return (
    <>
      <small className="tw-text-neutral-grey-1 tw-bg-neutral-grey-4 tw-p-2">Recent Transactions</small>
      <div className="tw-mt-4 tw-bg-neutral-light tw-rounded-md">
        {transactions?.length ? (
          <>
            <TransactionsTable
              transactionsByDate={transactionsByDate}
              accountNames={accountNames}
              productType={productType}
            />
            <div className="tw-flex tw-justify-end tw-mt-4">
              <Link to={transactionsUrl}>
                <div className="tw-flex tw-items-center">
                  <small className="tw-font-semibold tw-text-primary-dark-green tw-mr-2">See all transactions</small>
                  <BiArrowBack className="tw-text-primary-dark-green tw-transform tw-rotate-180" />
                </div>
              </Link>
            </div>
          </>
        ) : (
          <EmptyState />
        )}
      </div>
    </>
  );
};

const TransactionsTable = ({ transactionsByDate, accountNames, productType }) => {
  const today = formatDateTime(new Date());

  return (
    <table className="tw-table tw-table-auto tw-w-full">
      <thead>
        <tr>
          <th className="tw-p-3 tw-text-sm tw-text-neutral-grey-1 tw-font-normal">Type</th>
          <th className="tw-p-3 tw-text-sm tw-text-neutral-grey-1 tw-font-normal">From</th>
          <th className="tw-p-3 tw-text-sm tw-text-neutral-grey-1 tw-font-normal">To</th>
          <th className="tw-p-3 tw-text-sm tw-text-neutral-grey-1 tw-font-normal">Amount</th>
          <th className="tw-p-3 tw-text-sm tw-text-neutral-grey-1 tw-font-normal tw-text-right">Currency</th>
        </tr>
      </thead>
      {Object.keys(transactionsByDate).map((date) => (
        <TableSection
          key={date}
          date={date}
          transactions={transactionsByDate[date]}
          isToday={today === date}
          accountNames={accountNames}
          productType={productType}
        />
      ))}
    </table>
  );
};

const TableSection = ({ date, transactions, isToday, accountNames, productType }) => (
  <tbody>
    <tr>
      <th className="tw-bg-neutral-grey-5 tw-p-3 tw-text-sm tw-text-neutral-grey-1 tw-font-normal" colSpan={5}>
        {isToday ? 'Today' : date}
      </th>
    </tr>
    {transactions.map((transaction) => {
      const accountName = accountNames.find((name) => name === transaction.to);

      return (
        <TransactionRow
          key={transaction.transactionId}
          transaction={transaction}
          accountName={accountName}
          productType={productType}
        />
      );
    })}
  </tbody>
);

const TransactionRow = ({ transaction, accountName, productType }) => {
  const formattedAmount = formatTransactionTableAmount({ accountName, transaction, productType });
  const isNegative = formattedAmount.includes('-');

  return (
    <tr>
      <td className="tw-p-3 tw-text-sm tw-border-b tw-border-neutral-grey-5">
        <TransactionType transaction={transaction} />
      </td>
      <td className="tw-p-3 tw-text-sm tw-border-b tw-border-neutral-grey-5">{transaction.from}</td>
      <td className="tw-p-3 tw-text-sm tw-border-b tw-border-neutral-grey-5">{transaction.to}</td>
      <td
        className={`tw-p-3 tw-text-sm tw-border-b tw-border-neutral-grey-5 ${
          isNegative ? '' : 'tw-text-semantic-success'
        }`}
      >
        {formattedAmount}
      </td>
      <td className="tw-p-3 tw-text-sm tw-border-b tw-border-neutral-grey-5">
        <div className="tw-flex tw-items-center tw-justify-end">
          <div className="tw-rounded-full tw-bg-neutral-grey-4 tw-p-0.5 tw-mt-1 tw-mr-2">
            <div className="tw-rounded-full tw-bg-neutral-light tw-p-0.5">
              <CurrencyFlag currency={transaction.amount.currency} size={14} />
            </div>
          </div>
          <span>{transaction.amount.currency}</span>
        </div>
      </td>
    </tr>
  );
};

const EmptyState = () => <small>You have no recent transactions.</small>;

export default RecentTransactions;
